import { useMemo } from 'react';
import { Frame } from '..';
import { useRouter } from 'next/router';
import { getPathname } from '../../helpers/url.helper';
import { QueryParams } from '../../request/utils/buildURL';
import { useQuery } from 'react-query';
import { fetchFrame } from '~/lib/fetchFrame';
import { useMyKompanContext } from '../../myKompanContext/hooks/useMyKompanContext';
import { useMarket } from '../../market';

export const generateFrameQueryKey = ({
    params,
    culture,
    market,
}: {
    params: QueryParams;
    culture: string;
    market: string;
}) => {
    return ['frame', `${culture}-${market}`.toLowerCase(), params];
};

/**
 * Frame hook (top menu, footer, settings, and other things that are not page-specific).
 * Will load initial data provided by context on initial load, and fetch new values when culture or market changes
 * Always try to provide a value, even if it's from the previously loaded frame, but the isLoading will indicate
 * if it's serving stale data
 */
export const useFrame = () => {
    const router = useRouter();
    const { culture, market } = useMarket();
    const { isMyKompan } = useMyKompanContext();
    const routerUrl = router.asPath;

    const url = useMemo(() => {
        const pathname = getPathname(routerUrl);
        return pathname;
    }, [routerUrl]);

    const params = useMemo(() => {
        const params: QueryParams = {};

        if (routerUrl.includes('preview=true')) {
            params.preview = true;
        }
        if (isMyKompan) {
            params.isMyKompan = true;
        }
        return params;
    }, [url, routerUrl]);

    const { data, isLoading } = useQuery<Frame>({
        queryFn: () => {
            return fetchFrame({ params, culture, market });
        },
        enabled: true,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: generateFrameQueryKey({ params, culture, market }),
    });

    return { data: data, isLoading, isMyKompan };
};
